import { type DateRange } from "@src/appV2/lib";
import { differenceInMinutes } from "date-fns";

/**
 * Outputs duration of a date range in the format of "Xh YYm"
 * Examples: "8h 30m", "8h 00m", "0h 45m"
 * Returns "0h 00m" for zero or negative durations
 */
export function formatDateRangeDurationCompact(dateRange: DateRange): string {
  const { startDate, endDate } = dateRange;

  const durationInMinutes = differenceInMinutes(endDate, startDate);

  if (durationInMinutes <= 0) {
    return "0h 00m";
  }

  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  // Ensure minutes are always two digits
  const paddedMinutes = minutes.toString().padStart(2, "0");

  return `${hours}h ${paddedMinutes}m`;
}
