interface GetSafeAreaPaddingsProps {
  /**
   * Decides whether to apply safe area padding on the top side
   */
  top?: boolean;
  /**
   * Decides whether to apply safe area padding on the bottom side
   */
  bottom?: boolean;
  /**
   * Decides whether to apply safe area padding on the left side
   */
  left?: boolean;
  /**
   * Decides whether to apply safe area padding on the right side
   */
  right?: boolean;
  /**
   * Fallback value for top padding. Will be used if there is no device inset or provided value is bigger than device inset
   */
  fallbackTop?: string;
  /**
   * Fallback value for bottom padding. Will be used if there is no device inset or provided value is bigger than device inset
   */
  fallbackBottom?: string;
  /**
   * Fallback value for left padding. Will be used if there is no device inset or provided value is bigger than device inset
   */
  fallbackLeft?: string;
  /**
   * Fallback value for right padding. Will be used if there is no device inset or provided value is bigger than device inset
   */
  fallbackRight?: string;
}

/**
 * This complex type is used to ensure that the result of `getSafeAreaPaddings` is a record with only values defined by the props keys
 * If you call getSafeAreaPaddings with all false values, you will get an empty object and typescript will be aware of that
 * Same as if you call it with partial true values, you will get an object with only the defined keys
 */
type SafeAreaPaddingResult<T extends GetSafeAreaPaddingsProps> = {
  paddingTop: T["top"] extends true ? string : never;
  paddingBottom: T["bottom"] extends true ? string : never;
  paddingLeft: T["left"] extends true ? string : never;
  paddingRight: T["right"] extends true ? string : never;
} extends Record<string, never>
  ? Record<string, unknown>
  : Pick<
      {
        paddingTop: string;
        paddingBottom: string;
        paddingLeft: string;
        paddingRight: string;
      },
      {
        [K in keyof T]: T[K] extends true
          ? K extends "top"
            ? "paddingTop"
            : K extends "bottom"
            ? "paddingBottom"
            : K extends "left"
            ? "paddingLeft"
            : K extends "right"
            ? "paddingRight"
            : never
          : never;
      }[keyof T]
    >;

/**
 * Returns object with padding values that apply safe area insets if needed
 * Use fallback values to specify default padding values that are used when safe area insets are SMALLER than fallbacks
 * See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
 */
export function getSafeAreaPaddings<T extends GetSafeAreaPaddingsProps>(
  props: T
): SafeAreaPaddingResult<T> {
  const {
    top,
    bottom,
    left,
    right,
    fallbackTop = "0",
    fallbackBottom = "0",
    fallbackLeft = "0",
    fallbackRight = "0",
  } = props;

  const result: Record<string, string> = {
    ...(top && { paddingTop: `max(env(safe-area-inset-top), ${fallbackTop})` }),
    ...(bottom && { paddingBottom: `max(env(safe-area-inset-bottom), ${fallbackBottom})` }),
    ...(left && { paddingLeft: `max(env(safe-area-inset-left), ${fallbackLeft})` }),
    ...(right && { paddingRight: `max(env(safe-area-inset-right), ${fallbackRight})` }),
  };

  return result as SafeAreaPaddingResult<T>;
}
