import { type Components } from "@mui/material/styles";

import {
  BORDER_PRIMARY_COLOR,
  DISABLED_PRIMARY_COLOR,
  DISABLED_SECONDARY_COLOR,
  PRIMARY_COLOR,
  SURFACE_SECONDARY_COLOR,
} from "../colors";
import { BORDER_RADIUS_LARGE, BORDER_RADIUS_XLARGE } from "../common";
import { BODY_2_FONT_SIZE } from "../typography";

export const muiToggleButtonOverrides: Components = {
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS_LARGE,
        border: "none",
        padding: "0.25rem",
        justifyContent: "space-between",
        width: "-webkit-fill-available",
        fontSize: BODY_2_FONT_SIZE,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: "0.75rem 1rem",
        borderRadius: `${BORDER_RADIUS_XLARGE} !important`,
        border: `1px solid ${BORDER_PRIMARY_COLOR} !important`,
        backgroundColor: SURFACE_SECONDARY_COLOR,
        color: PRIMARY_COLOR,
        height: "40px",

        "&.Mui-selected": {
          border: `1px solid ${PRIMARY_COLOR} !important`,
          backgroundColor: SURFACE_SECONDARY_COLOR,

          "&:hover": {
            backgroundColor: SURFACE_SECONDARY_COLOR,
          },
        },

        "&:hover": {
          border: `1px solid ${PRIMARY_COLOR} !important`,
          backgroundColor: SURFACE_SECONDARY_COLOR,
        },

        "&.Mui-disabled": {
          color: DISABLED_PRIMARY_COLOR,
          border: `1px solid ${DISABLED_SECONDARY_COLOR} !important`,
        },
      },
    },
  },
};
