/**
 * A hook to navigate to an anchor on the page.
 * Returns a function that can be called to navigate to the anchor.
 *
 * @example
 * const navigateToAnchor = useAnchorNavigation();
 *
 * <Button onClick={() => navigateToAnchor("cancellation-policies")}>
 *   Cancellation Policies
 * </Button>
 */
export function useAnchorNavigation() {
  return (anchorId: string) => {
    const anchorElement = document.querySelector(`#${anchorId}`);

    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
}
