import { type DateRange } from "@src/appV2/lib/Calendar/types";

import { formatTime } from "./formatTime";

/**
 * Formats a date range into a string in the format "9:30 am - 2:15 pm"
 */
export function formatTimeRange(dateRange: DateRange): string {
  const startTime = formatTime(dateRange.startDate);
  const endTime = formatTime(dateRange.endDate);

  return `${startTime} - ${endTime}`;
}
