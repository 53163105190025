const VALID_RATINGS = [1, 2, 3, 4, 5] as const;

type ValidRating = (typeof VALID_RATINGS)[number];

export interface RatingBreakdown {
  rating: ValidRating;
  numberOfRatings: number;
}

export interface AggregatedRating {
  rating: ValidRating;
  percentage: number;
}

/**
 * Aggregates rating breakdowns into a list of aggregated ratings with percentages.
 * Expects ratings from 1-5.
 */
export function aggregateRatingBreakdowns(ratingBreakdowns: RatingBreakdown[]): AggregatedRating[] {
  const counts: Record<number, number> = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  const totalRatings = ratingBreakdowns.reduce(
    (sum, breakdown) => sum + breakdown.numberOfRatings,
    0
  );

  ratingBreakdowns.forEach((breakdown) => {
    counts[breakdown.rating] += breakdown.numberOfRatings;
  });

  return Object.entries(counts)
    .map(([rating, count]) => ({
      rating: Number(rating) as ValidRating,
      percentage: totalRatings > 0 ? (count / totalRatings) * 100 : 0,
    }))
    .sort((a, b) => b.rating - a.rating);
}
