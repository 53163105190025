import type { Shift } from "@src/appV2/Shifts/Shift/types";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { TimeSlot } from "../Shift/OpenShifts/types";

export type ShiftTimeSlotCount = Record<TimeSlot, number>;
export type ShiftsByDateAndTimeSlot = Record<string, ShiftTimeSlotCount>;

// Forked from @src/appV2/OpenShifts/utils/groupShiftsByDateAndPeriod
// but using TimeSlot instead of ShiftPeriod
export function groupShiftsByDateAndTimeSlot(
  shifts: Shift[], // TODO: use a shift type with time slots
  workerTimezone: string
): ShiftsByDateAndTimeSlot {
  const normalizedShifts: ShiftsByDateAndTimeSlot = {};

  for (const shift of shifts) {
    const zonedDate = utcToZonedTime(parseISO(shift.start), workerTimezone);
    const startDate = format(zonedDate, "yyyy-MM-dd");
    const shiftName = shift.name as unknown as TimeSlot;

    if (!normalizedShifts[startDate]) {
      normalizedShifts[startDate] = {
        [TimeSlot.AM]: 0,
        [TimeSlot.PM]: 0,
        [TimeSlot.NOC]: 0,
        [TimeSlot.SURG]: 0,
      };
    }

    normalizedShifts[startDate][shiftName] += 1;
  }

  return normalizedShifts;
}
