import { PRIMARY_COLOR } from "./colors";

export const CAPTION_FONT_SIZE = "0.75rem"; // 12px
export const CAPTION_LINE_HEIGHT = 1.21; // 14.5px

export const BODY_2_FONT_SIZE = "0.875rem"; // 14px
export const BODY_2_LINE_HEIGHT = 1.21; // 17px
export const BODY_2_LETTER_SPACING = "-0.00625rem"; // -0.1px

export const BODY_1_FONT_SIZE = "1rem"; // 16px
export const BODY_1_LINE_HEIGHT = 1.06; // 17px
export const BODY_1_LETTER_SPACING = "-0.00625rem"; // -0.1px

export const H5_FONT_SIZE = "1.25rem"; // 20px
export const H5_LINE_HEIGHT = 1.2; // 24px
export const H5_LETTER_SPACING = "-0.0125rem"; // -0.2px

export const H4_FONT_SIZE = "1.5rem"; // 24px
export const H4_LINE_HEIGHT = 1.21; // 29px
export const H4_LETTER_SPACING = "-0.0125rem"; // -0.2px

export const H3_FONT_SIZE = "2rem"; // 32px
export const H3_LINE_HEIGHT = 1.09; // 35px

export const H2_FONT_SIZE = "3rem"; // 48px
export const H2_LINE_HEIGHT = 1.1; // 52.8px

export const H1_FONT_SIZE = "4rem"; // 64px
export const H1_LINE_HEIGHT = 1.2; // 76.8px
export const H1_LETTER_SPACING = "-0.03125rem"; // -0.5px

export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_SEMIBOLD = 500;

export const muiThemeTypography = {
  allVariants: {
    fontFamily:
      '"PPNeueMontreal", "SF Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif',
    color: PRIMARY_COLOR,
    fontWeight: FONT_WEIGHT_REGULAR,
    lineHeight: "inherit",
  },
  h1: {
    fontSize: H1_FONT_SIZE,
    fontWeight: FONT_WEIGHT_REGULAR,
    lineHeight: H1_LINE_HEIGHT,
    letterSpacing: H1_LETTER_SPACING,
  },
  h2: {
    fontSize: H2_FONT_SIZE,
    fontWeight: FONT_WEIGHT_REGULAR,
    lineHeight: H2_LINE_HEIGHT,
  },
  h3: {
    fontSize: H3_FONT_SIZE,
    fontWeight: FONT_WEIGHT_REGULAR,
    lineHeight: H3_LINE_HEIGHT,
  },
  h4: {
    fontSize: H4_FONT_SIZE,
    fontWeight: FONT_WEIGHT_SEMIBOLD,
    lineHeight: H4_LINE_HEIGHT,
    letterSpacing: H4_LETTER_SPACING,
  },
  h5: {
    fontSize: H5_FONT_SIZE,
    fontWeight: FONT_WEIGHT_SEMIBOLD,
    lineHeight: H5_LINE_HEIGHT,
    letterSpacing: H5_LETTER_SPACING,
  },

  body1: {
    fontSize: BODY_1_FONT_SIZE,
    lineHeight: BODY_1_LINE_HEIGHT,
    letterSpacing: BODY_1_LETTER_SPACING,
  },
  body2: {
    fontSize: BODY_2_FONT_SIZE,
    lineHeight: BODY_2_LINE_HEIGHT,
    letterSpacing: BODY_2_LETTER_SPACING,
  },
  caption: {
    fontSize: CAPTION_FONT_SIZE,
    lineHeight: CAPTION_LINE_HEIGHT,
  },
  fontWeightLight: FONT_WEIGHT_LIGHT,
  fontWeightRegular: FONT_WEIGHT_REGULAR,
  // medium from MUI is `semibold` in our design system
  fontWeightMedium: FONT_WEIGHT_SEMIBOLD,
  // we don't use `bold` in our design system and we fall back to semibold
  fontWeightBold: FONT_WEIGHT_SEMIBOLD,
};
