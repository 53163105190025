import { isDefined } from "@clipboard-health/util-ts";

import { TimeSlot } from "../Shift/OpenShifts/types";
import type { ShiftTimeSlotCount } from "./groupShiftsByDateAndTimeSlot";

export function getBookedShiftTimeSlotsFromWorkerDayShifts(
  workerDayShifts: ShiftTimeSlotCount | undefined
): TimeSlot[] {
  if (!isDefined(workerDayShifts)) {
    return [];
  }

  return Object.values(TimeSlot).filter((timeSlot) => workerDayShifts[timeSlot] > 0);
}
