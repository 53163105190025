import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { z } from "zod";

export const GET_INSTANT_PAY_STATUS_PATH = "/agentprofile/instant-pay-status";
export const GET_INSTANT_PAY_STATUS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${GET_INSTANT_PAY_STATUS_PATH}`;

const instantPayStatusResponseSchema = z.object({
  instantPayStatus: z.boolean(),
  employmentStatus: z.string().optional(),
  isHCPInstantPayProhibited: z.boolean().optional(),
});

export type InstantPayStatusResponse = z.infer<typeof instantPayStatusResponseSchema>;

export function useWorkerInstantPayStatus(
  options: UseGetQueryOptions<InstantPayStatusResponse> = {}
) {
  return useGetQuery({
    url: GET_INSTANT_PAY_STATUS_URL,
    responseSchema: instantPayStatusResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_INSTANT_PAY_STATUS_FAILURE,
    },
    ...options,
  });
}

export function useIsWorkerInstantPayEnabled(
  options: UseGetQueryOptions<InstantPayStatusResponse> = {}
): boolean {
  const { data } = useWorkerInstantPayStatus(options);
  return Boolean(data?.instantPayStatus);
}
