import { createTheme, type Shadows, type Theme } from "@mui/material";

import { muiThemePalette } from "./colors";
import {
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_MEDIUM,
  BORDER_RADIUS_SMALL,
  BORDER_RADIUS_XLARGE,
  BORDER_WIDTH_REGULAR,
  BORDER_WIDTH_THICK,
  BORDER_WIDTH_THIN,
  ELEVATION_1,
  ELEVATION_2,
  ELEVATION_3,
  ELEVATION_4,
  SPACINGS,
} from "./common";
import {
  ICON_SIZE_EXTRA_LARGE,
  ICON_SIZE_EXTRA_SMALL,
  ICON_SIZE_LARGE,
  ICON_SIZE_MEDIUM,
  ICON_SIZE_SMALL,
  muiThemeComponents,
} from "./components";
import { BUTTON_SIZE_LARGE, BUTTON_SIZE_MEDIUM, BUTTON_SIZE_SMALL } from "./overrides/button";
import {
  ICON_BUTTON_SIZE_EXTRA_SMALL,
  ICON_BUTTON_SIZE_LARGE,
  ICON_BUTTON_SIZE_MEDIUM,
  ICON_BUTTON_SIZE_SMALL,
} from "./overrides/iconButton";
import { muiThemeTypography } from "./typography";

declare module "@mui/material/styles" {
  interface CustomBorderRadius {
    borderRadius?: {
      small: string;
      medium: string;
      large: string;
      xLarge: string;
    };
  }

  interface CustomButtonSize {
    buttonSize?: {
      small: string;
      medium: string;
      large: string;
    };
  }

  interface CustomIconButtonSize {
    iconButtonSize?: {
      xSmall: string;
      small: string;
      medium: string;
      large: string;
    };
  }

  interface CustomIconSize {
    iconSize?: {
      xSmall: string;
      small: string;
      medium: string;
      large: string;
      xLarge: string;
    };
  }

  interface CustomBorderWidth {
    borderWidth: {
      thin: string;
      regular: string;
      thick: string;
    };
  }

  interface CustomProps
    extends CustomBorderRadius,
      CustomButtonSize,
      CustomIconButtonSize,
      CustomIconSize,
      CustomBorderWidth {}

  interface Theme extends CustomProps {}
  interface ThemeOptions extends CustomProps {}
}

export function getShiftDiscoveryTheme(): Theme {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    transitions: {
      duration: {
        leavingScreen: 225,
        enteringScreen: 225,
      },
    },
    borderRadius: {
      small: BORDER_RADIUS_SMALL,
      medium: BORDER_RADIUS_MEDIUM,
      large: BORDER_RADIUS_LARGE,
      xLarge: BORDER_RADIUS_XLARGE,
    },
    buttonSize: {
      small: BUTTON_SIZE_SMALL,
      medium: BUTTON_SIZE_MEDIUM,
      large: BUTTON_SIZE_LARGE,
    },
    iconButtonSize: {
      xSmall: ICON_BUTTON_SIZE_EXTRA_SMALL,
      small: ICON_BUTTON_SIZE_SMALL,
      medium: ICON_BUTTON_SIZE_MEDIUM,
      large: ICON_BUTTON_SIZE_LARGE,
    },
    iconSize: {
      xSmall: ICON_SIZE_EXTRA_SMALL,
      small: ICON_SIZE_SMALL,
      medium: ICON_SIZE_MEDIUM,
      large: ICON_SIZE_LARGE,
      xLarge: ICON_SIZE_EXTRA_LARGE,
    },
    borderWidth: {
      thin: BORDER_WIDTH_THIN,
      regular: BORDER_WIDTH_REGULAR,
      thick: BORDER_WIDTH_THICK,
    },
    zIndex: {
      // TODO: Check if this is still relevant
      appBar: 2000,
    },
    spacing: SPACINGS,
    shadows: [
      "none",
      ELEVATION_1,
      ELEVATION_2,
      ELEVATION_3,
      ELEVATION_4,
      // we don't support higher elevations than 3
      ...Array.from<string>({ length: 20 }).fill("none"),
    ] as Shadows,
    typography: muiThemeTypography,
    palette: muiThemePalette,
    components: muiThemeComponents,
  });
}
